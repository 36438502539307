import { performRefreshToken } from "core/services/authServices";
import { decodeJwt } from "jose";

const inMemoryJWTManager = () => {
  let inMemoryJWT_: string | null = null;
  let refreshTimeoutId_: any;
  let userId_: string | null = null;
  let userName_: string | null = null;

  const storageKey = "logout";

  window.addEventListener("storage", (event) => {
    if (event.key === storageKey) {
      inMemoryJWT_ = null;
    }
  });

  const startRefreshTokenTimer = (tokenExpiration: number) => {
    const currentDateTimeWith5SecOffset = new Date(Date.now() + 5000);
    const tokenExpirationDate = new Date(tokenExpiration * 1000);

    const delayInMilliSeconds = tokenExpirationDate.getTime() - currentDateTimeWith5SecOffset.getTime();
    // Fire five seconds before JWT expires
    const timeoutTrigger = Math.floor(delayInMilliSeconds);
    console.debug("timeouTriggerInSeconds:  ", timeoutTrigger / 1000);

    refreshTimeoutId_ = window.setTimeout(() => {
      performRefreshToken()
        .then((res: any) => {
          const { name, jwtToken, refreshToken, uid } = res;
          setToken(uid, name, jwtToken, refreshToken);
        })
        .catch(console.error);
    }, timeoutTrigger);
  };

  const abortRefreshTokenTimer = () => {
    if (refreshTimeoutId_) {
      window.clearTimeout(refreshTimeoutId_);
    }
  };

  const getToken = () => {
    if (inMemoryJWT_ === null) {
      const localJwtToken = localStorage.getItem("jwtToken");
      inMemoryJWT_ = localJwtToken;
    }
    return inMemoryJWT_;
  };

  const getUserId = () => {
    return userId_;
  };

  const getUserName = () => {
    return userName_;
  };

  const setToken = (userId: string, userName: string, jwtToken: string, refreshToken: string) => {
    const jwtPayload = decodeJwt(jwtToken);
    console.debug(`inMemoryJwtService::setToken called with expiration ${jwtPayload.exp}`);

    userId_ = userId;
    userName_ = userName;
    inMemoryJWT_ = jwtToken;
    startRefreshTokenTimer(jwtPayload.exp!);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("jwtToken", jwtToken);

    return true;
  };

  const deleteToken = () => {
    console.debug("inMemoryJwtService::deleteToken called");

    inMemoryJWT_ = null;
    userId_ = null;
    userName_ = null;
    abortRefreshTokenTimer();
    window.localStorage.setItem(storageKey, Date.now().toString());
    window.localStorage.setItem("refreshToken", "");
    window.localStorage.setItem("jwtToken", "");

    return true;
  };

  const refreshToken = async () => {
    const token = localStorage.getItem("refreshToken");
    if (!token) {
      throw new Error("No refresh token found");
    }

    const { name, jwtToken, refreshToken, uid } = await performRefreshToken();
    setToken(uid, name, jwtToken, refreshToken);
  };

  return {
    getToken,
    setToken,
    deleteToken,
    getUserId,
    getUserName,
    refreshToken,
  };
};

export default inMemoryJWTManager();

export const isValidEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi;
  return emailPattern.test(email);
};

export const getInitialsFromFullName = (fullName: string) => {
  return fullName
    .split(" ")
    .map((char) => char[0])
    .join("");
};

import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { DashboardOutlined, LogoutOutlined, SettingOutlined, SwapOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, MenuProps, Typography } from "antd";

import SearchInput from "components/search/searchinput";
import { LoginContext } from "context/LoginContext";
import { AntThemeContext, ThemeVariants } from "context/ThemeContext";
import constants from "core/common/constants";
import { PRIVATE_ROUTES } from "core/common/routes";
import { Desktop } from "core/common/useMediaQuery";
import { getInitialsFromFullName } from "utils/helpers";

const { Title } = Typography;

const NavBar = () => {
  const { setCurrentTheme } = useContext(AntThemeContext);

  const { user } = useContext(LoginContext);

  const initials = getInitialsFromFullName(user?.name ?? "U");
  const menuItems = useMemo(() => mapRoutesToItems(PRIVATE_ROUTES), []);
  const items: MenuProps["items"] = [
    {
      label: <Link to={constants.routes.home}>Dashboard</Link>,
      key: "1",
      icon: <DashboardOutlined />,
    },
    {
      label: "Switch Themes ",
      icon: <SwapOutlined rev={undefined} />,
      key: "2",
      children: [
        {
          key: "2-0",
          label: "Dark",
        },
        {
          key: "2-1",
          label: "Dark Condensed",
        },
        {
          key: "2-2",
          label: "Light",
        },
        {
          key: "2-3",
          label: "Light Condensed",
        },
        {
          key: "2-4",
          label: "System",
        },
        {
          key: "2-5",
          label: "System Condensed",
        },
      ],
    },
    {
      label: <Link to={`/settings`}>Settings</Link>,
      key: "3",
      icon: <SettingOutlined />,
    },
    {
      label: <Link to={`/logout`}>Logout</Link>,
      key: "4",
      icon: <LogoutOutlined />,
    },
  ];
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "2-0":
        setCurrentTheme(ThemeVariants.dark);
        break;
      case "2-1":
        setCurrentTheme(ThemeVariants.darkCondensed);
        break;
      case "2-2":
        setCurrentTheme(ThemeVariants.light);
        break;
      case "2-3":
        setCurrentTheme(ThemeVariants.lightCondensed);
        break;
      case "2-4":
        setCurrentTheme(ThemeVariants.system);
        break;
      case "2-5":
        setCurrentTheme(ThemeVariants.systemCondensed);
        break;
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <Menu mode="horizontal" items={menuItems} defaultOpenKeys={undefined} style={{ width: "100%", border: "none" }} />
      <>
        <Desktop>
          {" "}
          <SearchInput placeholder="Quick Search" style={{ marginRight: 32, minWidth: 230 }} />
        </Desktop>
        <Dropdown menu={menuProps} placement="bottom" trigger={["click"]}>
          <Avatar
            shape="square"
            size={30}
            style={{
              backgroundColor: "#87d068",
              minWidth: "32px",
              minHeight: "32px",
              cursor: "pointer",
            }}
          >
            {initials}
          </Avatar>
        </Dropdown>
      </>
    </>
  );
};

const mapRoutesToItems = (routes: any[]) => {
  const navRoutes = routes.map((route) => {
    return {
      key: route.key,
      label: <Link to={`/${route.key.toLowerCase()}`}>{route.label}</Link>,
    };
  });
  navRoutes.unshift({
    key: "rythem",
    label: (
      <Link to={"/"} style={{ display: "flex", alignItems: "center" }}>
        <Title
          style={{ margin: 16, fontFamily: "sans-serif, Inter", fontSize: "1.5rem", fontWeight: 400, lineHeight: 1.2 }}
        >
          RYTHEM
        </Title>
      </Link>
    ),
  });
  return navRoutes;
};

export default NavBar;

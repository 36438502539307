import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { AntThemeProvider } from "context/ThemeContext";
import { ProtectedLayout } from "layouts/ProtectedLayout";
import { PublicLayout } from "layouts/PublicLayout";
import Logout from "pages/Logout";

import "./App.css";

function App() {
  const LazySignin = React.lazy(() => import("pages/SignIn"));
  const LazyPlanning = React.lazy(() => import("pages/planning/Planning"));
  const LazyPlan = React.lazy(() => import("pages/planning/Plan"));
  const LazyExecution = React.lazy(() => import("pages/execution/Execution"));
  const LazyTracking = React.lazy(() => import("pages/tracking/Tracking"));
  const LazySettings = React.lazy(() => import("pages/settings/Settings"));
  const LazyTeams = React.lazy(() => import("pages/teams/Teams"));
  const LazyDashboard = React.lazy(() => import("pages/Dashboard"));
  return (
    <>
      <AntThemeProvider>
        <Routes>
          <Route element={<PublicLayout />}>
            <Route
              path="/login"
              element={
                <Suspense>
                  <LazySignin />
                </Suspense>
              }
            />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route
              path="/"
              element={
                <Suspense>
                  <LazyDashboard />
                </Suspense>
              }
            />
            <Route
              path="planning"
              element={
                <Suspense>
                  <LazyPlanning />
                </Suspense>
              }
            />
            <Route
              path="planning/:planId"
              element={
                <Suspense>
                  <LazyPlan />
                </Suspense>
              }
            />

            <Route
              path="execution"
              element={
                <Suspense>
                  <LazyExecution />
                </Suspense>
              }
            />
            <Route
              path="tracking"
              element={
                <Suspense>
                  <LazyTracking />
                </Suspense>
              }
            />
            <Route
              path="teams"
              element={
                <Suspense>
                  <LazyTeams />
                </Suspense>
              }
            />
            <Route
              path="settings"
              element={
                <Suspense>
                  <LazySettings />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </AntThemeProvider>
    </>
  );
}

export default App;

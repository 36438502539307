import React from "react";
import { Outlet } from "react-router-dom";

import { Layout } from "antd";

const { Content } = Layout;

export const PublicLayout = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

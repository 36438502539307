import { IResolveParams } from "reactjs-social-login";

import constants from "./../common/constants";
import { ajaxCall, formRequestData } from "./ajaxService";

const loginUrl = `${constants.ajax.baseUrl}/login`;
const validateTokenUrl = `${constants.ajax.baseUrl}/user/validate`;
const logoutUrl = `${constants.ajax.baseUrl}/user/logout`;
const refreshTokenUrl = `${constants.ajax.baseUrl}/refresh`;

const performLogin = async (userData: object) => {
  const data = formRequestData(
    constants.ajax.methods.post,
    false,
    constants.ajax.credentials.include,
    JSON.stringify(userData)
  );

  return await ajaxCall(loginUrl, data);
};

const performLogin3P = async ({ provider, data }: IResolveParams) => {
  try {
    const response = await fetch(`${constants.ajax.baseUrl}/login3`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        provider: provider,
        data: data,
      }),
    });

    if (!response.ok) {
      const err = await response.json();
      console.error(err);
      throw new Error(err.message);
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const validateToken = async () => {
  const data = formRequestData(constants.ajax.methods.post, true);

  return await ajaxCall(validateTokenUrl, data);
};

const performLogout = async () => {
  return await ajaxCall(logoutUrl, formRequestData(constants.ajax.methods.get, true));
};

const hash = async (str: string) => {
  const utf8 = new TextEncoder().encode(str);
  return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("");
    return hashHex;
  });
};

const performRefreshToken = async () => {
  const localRefreshToken = localStorage.getItem("refreshToken");
  const h = await hash(localRefreshToken!);
  console.debug(`Refresh Token Hash ${h}`);
  const data = {
    headers: {
      Accept: "*/*",
      Host: constants.ajax.baseUrl,
      "Access-Control-Allow-Origin": constants.ajax.baseUrl,
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${localRefreshToken}`,
    },
    method: constants.ajax.methods.post,
  };
  return await ajaxCall(refreshTokenUrl, data);
};

export { performLogin, performLogout, performRefreshToken, validateToken, performLogin3P };

import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Col, Row } from "antd";
import { Card, Spin } from "antd";

import { LoginContext } from "context/LoginContext";
import inMemoryJwt from "core/services/inMemoryJwtService";

export default function Logout() {
  const navigate = useNavigate();
  const { setUser } = useContext(LoginContext);

  useEffect(() => {
    let logoutTimeout = setTimeout(() => {
      inMemoryJwt.deleteToken();

      setUser(null);

      navigate("/");
    }, 100);

    return () => {
      clearTimeout(logoutTimeout);
    };
  }, [navigate, setUser]);

  return (
    <>
      <div>
        <Row justify="center" align="middle" style={{ height: "100vh" }}>
          <Col>
            <Card title="Logging out..." bordered={true} style={{ width: 300, height: 300 }}>
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "10vh",
                  }}
                >
                  <Spin size="large" />
                </div>
              </>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export const PUBLIC_ROUTES = [
  { label: "Features", key: "features", path: "/features" },
  { label: "Why Rythem?", key: "why-rythem", path: "/why-rythem" },
  { label: "Pricing", key: "pricing", path: "/pricing" },
  { label: "Resources", key: "resources", path: "/resources" },
  { label: "Integrations", key: "integrations", path: "/integrations" },
];

export const PRIVATE_ROUTES = [
  { label: "Planning", key: "planning" },
  { label: "Execution", key: "execution" },
  { label: "Tracking", key: "tracking" },
  { label: "Settings", key: "settings" },
  { label: "Teams", key: "teams" },
];

export enum ENV {
  "local" = "http://127.0.0.1:5000",
  "dev" = "https://dev-api.rythem.app",
}

const constants = {
  ajax: {
    methods: {
      get: "GET",
      post: "POST",
    },
    baseUrl: ENV.dev,
    credentials: {
      include: "include",
    },
  },
  routes: {
    home: "/",
    login: "/login",
    signup: "/signup",
    // dashboard: "/dashboard",
    // profile: "/profile",
    // settings: "/settings",
    // forgotPassword: "/forgot-password",
    // resetPassword: "/reset-password",
    // verifyEmail: "/verify-email",
  },
};

export default constants;

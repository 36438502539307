import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Layout } from "antd";
import { theme as antdTheme } from "antd";

import NavBar from "components/NavBar";
import { LoginContext } from "context/LoginContext";
import { PlanContextProvider } from "context/PlansContext";
import constants from "core/common/constants";
import inMemoryJwt from "core/services/inMemoryJwtService";

const { Header, Content, Footer } = Layout;

export const ProtectedLayout = () => {
  const { user, setUser } = useContext(LoginContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { useToken } = antdTheme;
  const { token: theme } = useToken();

  const jwtToken = inMemoryJwt.getToken();

  useEffect(() => {
    if (!user || !jwtToken) {
      inMemoryJwt.deleteToken();
      setUser(null);
      navigate(constants.routes.login);
    }
  }, [jwtToken, navigate, setUser, user]);

  useEffect(() => {
    // console.debug("location changed", location);
    // Validate the token expiration and redirect to /login page if required
  }, [location]);

  return (
    <PlanContextProvider>
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            margin: 0,
            padding: "0 20px 0 0",
            backgroundColor: theme.colorBorderBg,
          }}
        >
          <NavBar />
        </Header>

        <Content style={{ padding: "0 50px", height: "100%", flexDirection: "column" }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center", height: "100px" }}>
          <div>Copyright 2023 RYTHEM</div>
        </Footer>
      </Layout>
    </PlanContextProvider>
  );
};

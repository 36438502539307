import React, { PropsWithChildren, createContext, useState } from "react";

import { Plan } from "generated/graphql/graphql";

interface PlanContextType {
  plans: Plan[];
  setPlans: React.Dispatch<React.SetStateAction<Plan[]>>;
}

export const PlanContext = createContext<PlanContextType>({} as PlanContextType);

export const PlanContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [plans, setPlans] = useState<Plan[]>([]);

  return <PlanContext.Provider value={{ plans, setPlans }}>{children}</PlanContext.Provider>;
};

import React, { PropsWithChildren, SetStateAction, createContext, useEffect } from "react";

import { useLocalStorage } from "core/common/useLocalStorage";
import { Dispatch, ILoggedInUser } from "data/ILoggedInUser";

interface LoginContextType {
  user: ILoggedInUser | null;
  setUser: Dispatch<SetStateAction<ILoggedInUser | null>>;
}
export const LoginContext = createContext<LoginContextType>({} as LoginContextType);

export const LoginContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useLocalStorage<ILoggedInUser | null>("rythemUser", null);

  useEffect(() => {
    const handleSessionValidity = (event: StorageEvent) => {
      if (event.key === "logout") {
        setUser(null);
      }
    };
    window.addEventListener("storage", handleSessionValidity);

    return () => {
      window.removeEventListener("storage", handleSessionValidity);
    };
  }, [setUser, user]);

  return (
    <LoginContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useIsLoggedIn = () => {
  const context = React.useContext(LoginContext);
  if (context === undefined) {
    throw new Error("IsLoggedIn must be used within a LoginContext");
  }

  return !!context.user;
};

import React, { useEffect, useRef, useState } from "react";

import { AutoComplete, Input } from "antd";
import type { SelectProps } from "antd/es/select";

import { useDebounce } from "usehooks-ts";

interface SearchInputProps extends SelectProps<string> {
  placeholder?: string;
  onSearch?: (value: string) => void;
  onPressEnter?: (value: string) => void;
}

const SearchInput = ({ placeholder, size, onSearch, ...rest }: SearchInputProps) => {
  let componentMounted = useRef<boolean>(false);
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(value, 500);

  const handleSearch = (event: string) => {
    setValue(event);
  };

  useEffect(() => {
    if (componentMounted.current === false) {
      return;
    }

    onSearch?.(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    componentMounted.current = true;
  }, []);

  return (
    <AutoComplete popupMatchSelectWidth={252} style={{ width: 300 }} onSearch={handleSearch} size={size} {...rest}>
      <Input.Search size={size} placeholder={placeholder} enterButton />
    </AutoComplete>
  );
};

export default SearchInput;

import inMemoryJwt from "core/services/inMemoryJwtService";

const formRequestData = (method: string, addAuthorization: boolean, credentials?: string, data?: string) => {
  const requestData: { [key: string]: any } = {
    headers: {
      Accept: "*/*",
      Host: "127.0.0.1:5000",
    },
    method: method,
  };

  if (process.env.NODE_ENV === "development") {
    requestData.headers["Access-Control-Allow-Origin"] = "http://localhost:5000";
  } else {
    requestData.headers["Access-Control-Allow-Origin"] = "http://dev.rythem.app";
  }

  requestData.headers["Access-Control-Allow-Credentials"] = "true";

  if (addAuthorization) {
    requestData.headers["Authorization"] = `Bearer ${inMemoryJwt.getToken()}`;
  }

  if (credentials) {
    requestData.credentials = credentials;
  }

  if (data) {
    requestData.headers["Content-Type"] = "application/json";
    requestData.body = data;
  }

  return requestData;
};

const ajaxCall = async (url: string, data: object) => {
  const response = await fetch(url, data);

  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }

  return response.json();
};

export { ajaxCall, formRequestData };

import React, { PropsWithChildren, createContext, useMemo } from "react";

import { App, ConfigProvider, theme } from "antd";

import { useLocalStorage } from "core/common/useLocalStorage";

export enum ThemeVariants {
  dark = "dark",
  light = "light",
  darkCondensed = "darkCondensed",
  lightCondensed = "lightCondensed",
  system = "system",
  systemCondensed = "systemCondensed",
}

/*
 *
 * This is the new theme switching context based on AntDesign. Right now it has support for MUI as well, but it needs to be removed.
 *
 */
interface AntThemeContextType {
  newTheme: ThemeVariants;
  toggleTheme: () => void;
  selectTheme: () => void;
  setCurrentTheme: (theme: ThemeVariants) => void;
}

export const AntThemeContext = createContext({
  newTheme: ThemeVariants.dark,
} as AntThemeContextType);

export const AntThemeProvider: React.FC<PropsWithChildren> = ({ children, ...props }) => {
  const [newTheme, setTheme] = useLocalStorage("anttheme", ThemeVariants.system);

  const toggleThemeFunc = () => {
    setTheme((prevThemeMode) => (prevThemeMode === ThemeVariants.dark ? ThemeVariants.light : ThemeVariants.dark));
  };

  const setCurrentTheme = (theme: ThemeVariants) => {
    setTheme(theme);
  };

  const selectThemeFunc = () => {
    switch (newTheme) {
      case ThemeVariants.dark:
        setTheme(ThemeVariants.darkCondensed);
        break;
      case ThemeVariants.darkCondensed:
        setTheme(ThemeVariants.light);
        break;
      case ThemeVariants.light:
        setTheme(ThemeVariants.lightCondensed);
        break;
      case ThemeVariants.lightCondensed:
      default:
        setTheme(ThemeVariants.dark);
    }
  };

  const IsSystemThemeDark = () => {
    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const ThemeVariantsToAntTheme = (option: ThemeVariants) => {
    switch (option) {
      case ThemeVariants.darkCondensed:
        return [theme.darkAlgorithm, theme.compactAlgorithm];
      case ThemeVariants.lightCondensed:
        return [theme.defaultAlgorithm, theme.compactAlgorithm];
      case ThemeVariants.dark:
        return theme.darkAlgorithm;
      case ThemeVariants.system:
      case ThemeVariants.systemCondensed:
        if (IsSystemThemeDark()) {
          return option === ThemeVariants.system ? theme.darkAlgorithm : [theme.darkAlgorithm, theme.compactAlgorithm];
        } else {
          return option === ThemeVariants.system
            ? theme.defaultAlgorithm
            : [theme.defaultAlgorithm, theme.compactAlgorithm];
        }
      case ThemeVariants.light:
      default:
        return theme.defaultAlgorithm;
    }
  };

  let antTheme = useMemo(() => {
    return {
      algorithm: ThemeVariantsToAntTheme(newTheme),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTheme]);

  return (
    <AntThemeContext.Provider
      value={{ newTheme, toggleTheme: toggleThemeFunc, selectTheme: selectThemeFunc, setCurrentTheme }}
    >
      <ConfigProvider theme={antTheme} {...props}>
        <App>{children}</App>
      </ConfigProvider>
    </AntThemeContext.Provider>
  );
};
